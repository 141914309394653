import { t } from "i18next";
import React from "react";
import i18n from "../../../i18n";

interface customProps {
    cancelModal(): void;
}

const OplVehicleModal = (props: customProps) => {

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full relative"> {/* Added relative here */}
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-2xl"
                    onClick={props.cancelModal}
                    aria-label={t("Close", i18n.language)}
                >
                    ✖
                </button>

                <h2 className="text-lg font-medium mb-4">
                    {t("Regarding Service for this vehicle, please contact:", i18n.language)}
                </h2>
                <ul className="mb-4">
                    <li>{t("Central Region: 0540812739", i18n.language)}</li>
                    <li>{t("Eastern Region: 0540826697", i18n.language)}</li>
                    <li>{t("Western Region: 0540840715", i18n.language)}</li>
                    <li>{t("Southern Region: 0540839183", i18n.language)}</li>
                </ul>
            </div>
        </div>
    );
};

export default OplVehicleModal;
