import { useEffect, useRef, useState } from "react";
import Icon from "../icons/Icons";
import CardLayout from "../common/Card";
import CardNames from "../../util/CardNames";
import TimeSelector from "../misc/TimeSelector";
import {
  format,
  addDays,
  subDays,
  isBefore,
  startOfDay,
  isAfter,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import apiService from "../../api/api";
import { getItem, setItem } from "../../util/storageService";
import { DateData, Shift, TimeSlotData } from "../../type/Service";
import { CardData } from "../../type/Storage";
import { updateOrAddCardData } from "../../util/ManageSummary";
import { UserData } from "../../type/UserDetails";
import FullLoader from "../common/Loader";
import handleApiError from "../../util/ErrorService";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
interface customProps {
  cardActive: boolean;
  onSubmit: (nextcomponent: string) => void;
  edit?: string;
  editPrompt(card: any): void;
}

const ChoodeDateTime = (props: customProps) => {
  const [nextcomponent, setSetcomponent] = useState("last");
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [btnDisable, setbtnDisable] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  const todayDate = startOfDay(new Date());
  const nextDay = addDays(todayDate, 1);

  const today = startOfDay(new Date());
  const end = addDays(todayDate, 30);

  const [startDate, setStartDate] = useState(nextDay);
  const [selDate, setSelDate] = useState<any>(null);
  const [endDate] = useState(parseISO(format(end, "yyyy-MM-dd")));
  const [dateFrom, setDateFrom] = useState(format(nextDay, "yyyy-MM-dd"));
  const [dateTo, setDateTo] = useState(format(addDays(today, 6), "yyyy-MM-dd"));

  const [timeSlot, setSlot] = useState<DateData[]>([]);
  const [slots, setSlots] = useState<any>([]);
  const [shifts, setShifts] = useState<any>(undefined);
  const [selTime, setselTime] = useState<any>();
  const [summaryData, setSummaryData] = useState<any>();
  const currentSteplocal = getItem("currentStep");
  const [currentStep, setCurrentStep] = useState(currentSteplocal);

  const parentDivRef = useRef<any>(null);
  const [divParentWidth, setParentDivWidth] = useState(0);
  const [dayLength, setDayLength] = useState(6);

  const [loader, setLoader] = useState(false);
  const [availabilityCount, setAvailabilityCount] = useState(7);

  const cardData = getItem("userSummary");
  const card = cardData?.find(
    (item: { card_name: any }) => item.card_name === CardNames?.DATE_TIME
  );
  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);

      const isEditing =
        props.edit !== undefined && props.edit !== null && props.edit !== "";

      if (isEditing) {
        setStartDate(nextDay);
        setSelDate(null);
        setShifts(undefined);
        setSlot([]);
        setbtnDisable(true);
        setAvailabilityCount(7)
      } else {
        getAvailability(nextDay);
        setAvailabilityCount(7)
      }
    }
  }, [props?.cardActive, props?.edit]);

  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
    const currentStep = getItem("currentStep");
    if (currentStep === CardNames?.DATE_TIME) {
      getAvailability(nextDay);
      setAvailabilityCount(7)
    }
  }, [props?.cardActive, props?.edit]);

  useEffect(() => {
    const updateWidth = () => {
      if (parentDivRef.current) {
        setParentDivWidth(parentDivRef.current.offsetWidth);
      }
    };
    // Set width on load
    updateWidth();
    const handleResize = () => {
      updateWidth();
    };
    // Set initial day length based on window width
    const windowWidth = window.innerWidth;
    if (windowWidth >= 600) {
      setDayLength(7);
    } else if (windowWidth <= 570 && windowWidth >= 440) {
      setDayLength(5);
    } else if (windowWidth <= 439 && windowWidth >= 332) {
      setDayLength(4);
    } else if (windowWidth <= 331 && windowWidth >= 200) {
      setDayLength(3);
    } else {
      setDayLength(7);
    }
    // Optional: Update width if the window resizes
    window.addEventListener("resize", handleResize);
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setDateFrom(format(startDate, "yyyy-MM-dd"));
    setDateTo(format(addDays(startDate, 6), "yyyy-MM-dd"));
  }, [startDate]);

  const getAvailability = (start: Date) => {
    const summary = getItem("userSummary");
    setLoader(true);
    const odoVal = summary?.find(
      (item: { card_name: any }) => item.card_name === CardNames?.ODO_MILEAGE
    );
    const dealer = summary?.find(
      (item: { card_name: any }) =>
        item.card_name === CardNames?.DEALER_LOCATION
    );
    const vDetail = summary?.find(
      (item: { card_name: any }) => item.card_name === CardNames?.PLATE_NUMBER
    );
    const cartData = getItem("cart");
    const payload = {
      cartId: cartData?._id,
      dateFrom: format(start, "yyyy-MM-dd"),
      dateTo: format(addDays(start, 6), "yyyy-MM-dd"),
      vehicleId: vDetail?.temp?.vId,
      serviceCenterId: dealer?.temp?.selectedLocation,
    };
    apiService
      .post(`/app/customer/appointment-availability-v2`, payload)
      .then((response) => {
        const availableSlots = response?.data?.data;
        setSlots(availableSlots);
        // setAvailabilityCount(prevCount => prevCount + availableSlots.length);
        setLoader(false);
      })
      .catch((error) => {
        handleApiError(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    const summary = getItem("userSummary");
    const card = summary?.find(
      (item: { card_name: any }) => item.card_name === CardNames?.DATE_TIME
    );

    if (card) {
      setSummaryData(card);
      if (props.edit === "") setSubmit(true);
    }
  }, [isSubmit]);
  const generateDates = (start: any) => {
    const dates = [];
    for (let i = 0; i < dayLength; i++) {
      const date = addDays(start, i);
      const dateStr = format(date, "yyyy-MM-dd");
      const slotData = slots?.find(
        (slot: { date: string }) => slot.date === dateStr
      );

      const isActive = slotData ? "active" : "inactive";
      const capacity = slotData ? slotData.capacityFreePercentage : 0;
      dates.push(
        <div
          key={i}
          onClick={() => {
            selectOption(date);
            setShifts(slotData?.shifts[0]);
            setTimeout(() => {
              setLoader(false);
            }, 500);
          }}

          className={`w-[92px] h-[92px] rounded-md relative overflow-hidden flex justify-center items-center flex-col border border-[#E7E7E7]   ${date?.getTime() === selDate?.getTime()
            ? " shadow-[inset_0px_0px_0px_2px] shadow-primary"
            : "border border-[#E7E7E7]"
            } ${isActive === "active" ? "" : ``} ${capacity === 0
              ? "opacity-50 pointer-events-none "
              : "cursor-pointer "
            }}`}
        >
          {date?.getTime() === selDate?.getTime() && (
            <span className="absolute  left-0 top-0">
              <Icon name="active" />
            </span>
          )}
          <span className="block font-medium text-xs text-muted ">
            {format(date, "EEE")}
          </span>
          <h4 className="font-medium text-[10px] sm:text-lg block text-black ">
            {format(date, "dd MMM")}
          </h4>
          <div className="flex items-center gap-1">
            {capacity !== 0 && (
              <>
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill={`${capacity >= 75 ? "green" : ""}${capacity > 25 && capacity < 75 ? "orange" : ""
                    }${capacity <= 25 ? "red" : ""}`}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3.5" cy="3.5" r="3.5"></circle>
                </svg>
                <span className="block text-muted text-[10px]">
                  {" "}
                  {capacity}%
                </span>
              </>
            )}
            {capacity === 0 && (
              <span className="block text-muted text-[10px]"> -- </span>

            )}

          </div>
        </div>
      );
    }

    return dates;
  };

  const handleNext = () => {
    getAvailability(addDays(startDate, dayLength));
    setStartDate((prevDate) => addDays(prevDate, dayLength));
    setAvailabilityCount((prevCount) => prevCount + slots.length);
  };

  const handleBack = () => {
    setStartDate((prevDate) => {
      const newStartDate = subDays(prevDate, dayLength);
      return isBefore(newStartDate, today) ? prevDate : newStartDate;
    });
    // getAvailability();
    getAvailability(subDays(startDate, dayLength));
    setAvailabilityCount((prevCount) => Math.max(prevCount - slots.length, 0));
  };

  const isBackDisabled = isBefore(subDays(startDate, dayLength), today);
  const isNextDisabled = isAfter(addDays(startDate, dayLength), endDate);

  const selectOption = (selectOption: any) => {
    setLoader(true);
    setSelDate(selectOption);
  };

  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
  }, [props?.cardActive]);

  const submitData = () => {
    updateCart();
  };

  const updateCart = () => {
    const userDetails: UserData = getItem("userDetails");
    const summary = getItem("userSummary");
    const cart = getItem("cart");
    const cartId = cart?._id;

    const givenDate = new Date(selDate);

    // Given time
    const time = selTime;
    const [hours, minutes] = time.split(":");
    const isAM = time.includes("AM");

    // Convert hours to 24-hour format
    const hours24 = isAM ? parseInt(hours) % 12 : (parseInt(hours) % 12) + 12;

    // Set the time on the date
    let mergedDateTime = setHours(givenDate, hours24);
    mergedDateTime = setMinutes(mergedDateTime, parseInt(minutes));
    mergedDateTime = setSeconds(mergedDateTime, 0);

    const formattedDateTime = format(mergedDateTime, "yyyy-MM-dd'T'HH:mm:ss");
    const dateTime = moment.tz(
      formattedDateTime,
      "YYYY-MM-DD hh:mm A",
      "Asia/Riyadh"
    );

    // Convert to UTC
    const utcDateTime = dateTime.utc();

    // Format the date as ISO string
    const result = utcDateTime.format();

    const cartData = getItem("cart");

    const payload: any = {
      instance: "aljazirah",
      serviceType: "routine",
      serviceAt: "Service Center",
      serviceDate: result,
      cartId: cartData?._id,
    };
    setLoader(true);
    apiService
      .patch(
        `${process.env.REACT_APP_AUTOMOTIVE_API_DOMAIN}/api/cart/${cartId}`,
        payload
      )
      .then((response) => {
        setItem("serviceDate", formattedDateTime);
        setbtnDisable(false);
        setItem("currentStep", "last");
        props.onSubmit(nextcomponent);
        manageCardData();
        setSubmit(true);
        setLoader(false);
      })
      .catch((error) => {
        handleApiError(error);
        setLoader(false);
      });
  };

  const selectedTime = (time: any) => {
    setselTime(time);

    setbtnDisable(false);
  };

  const DateTimeFormatter = (date: any) => {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  };

  const manageCardData = () => {
    const data: CardData = {
      card_name: CardNames?.DATE_TIME,
      title: "Date and Time",
      temp: {
        time: selTime,
        date: selDate,
      },
      data: [
        {
          title: DateTimeFormatter(selDate) + " " + selTime,
          sub_title: "",
        },
      ],
    };

    updateOrAddCardData("", data, "userSummary");
  };

  return (
    <>
      <CardLayout
        bg={`${isSubmit ? " border-[#E9E9E9] border" : ""} ${props.cardActive ? "bg-[#FAFAFA] border-0" : ""
          }`}
      >
        <div className="relative">
          {loader && (
            <FullLoader
              fullLoader={false}
              iconColor="#023f88"
              class="w-[100px] text-primary"
            />
          )}
          <div className="cardHeader flex items-center">
            <Icon name={isSubmit ? "activeStep" : "inactiveStep"} />
            <div className="flex items-start flex-col ml-[15px]">
              <label className="font-medium text-lg text-black block rtl:mr-2">
                {t("Date and Time", i18n.language)}
              </label>

              {isSubmit && summaryData && (
                <>
                  <span className="font-medium text-sm block">
                    {summaryData?.data[0]?.title}
                  </span>
                  <span
                    onClick={() => {
                      props?.editPrompt(card);
                    }}
                    className={`${currentStep === CardNames?.DATE_TIME ? "hidden" : ""
                      } absolute ltr:right-0 cursor-pointer top-5 rtl:left-0`}
                  >
                    <Icon name="arrowLarge" />
                  </span>
                </>
              )}
            </div>
          </div>
          {!isSubmit && props?.cardActive && (
            <div className="cardBody mt-[28px] md:ml-[37px]" ref={parentDivRef}>
              <div>
                <label className="font-medium text-[15px] text-black block mb-[18px]">
                  {t("Choose date", i18n.language)}{" "}
                </label>
                <div className="calenderDiv flex gap-2 relative max-md:justify-center">
                  <>{generateDates(startDate)}</>

                  <svg
                    width="35"
                    onClick={() => {
                      if (
                        format(subDays(startDate, 1), "yyyy-MM-dd") !==
                        format(today, "yyyy-MM-dd")
                      ) {
                        handleBack();
                      }
                    }}
                    className={`absolute left-[-16px] rotate-180 cursor-pointer top-[28px] ${!isBefore(subDays(startDate, 1), today)
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                      }`}
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="17.5"
                      cy="17.5"
                      r="17"
                      fill="white"
                      stroke="#E7E7E7"
                    />
                    <path
                      d="M14.8972 12L21 18L14.8972 24L14 23.1179L19.2057 18L14 12.8821L14.8972 12Z"
                      fill="#636363"
                    />
                  </svg>

                  <svg
                    width="35"
                    onClick={availabilityCount < 56 ? handleNext : undefined}
                    className={`absolute right-[-8px] cursor-pointer top-[28px] ${availabilityCount >= 60 || isNextDisabled
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                      }`}
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="17.5"
                      cy="17.5"
                      r="17"
                      fill="white"
                      stroke="#E7E7E7"
                    />
                    <path
                      d="M14.8972 12L21 18L14.8972 24L14 23.1179L19.2057 18L14 12.8821L14.8972 12Z"
                      fill="#636363"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-8">
                {shifts ? (
                  <TimeSelector
                    timeSlot={shifts}
                    callBack={(time) => {
                      selectedTime(time);
                    }}
                  />
                ) : (
                  <>
                    <label className="font-medium text-[15px] text-black block mb-[18px]">
                      {t("No time slot available", i18n.language)}
                    </label>
                  </>
                )}
              </div>
              <div className="btnArea pt-8">
                <button
                  className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                  onClick={() => submitData()}
                  disabled={btnDisable}
                >
                  {t("Continue", i18n.language)}
                </button>
              </div>
            </div>
          )}
        </div>
      </CardLayout>
    </>
  );
};

export default ChoodeDateTime;
